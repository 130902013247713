<template>
  <div>
    <div class="#032436" style="padding-bottom: 3rem">
      <v-container fluid class="ma-0 pa-0">
        <v-img v-if="$vuetify.breakpoint.mdAndUp" :src="require('@/assets/img/site/banner-with-sign.png')">
          <v-btn to="/discography" color="secondary darken-1" dark x-large absolute rounded
            style="left: 12%; bottom: 20%; text-transform: none; width: 25%; min-width: 300px;">
            <!-- <v-icon left large class="mr-6"> mdi-headphones </v-icon> -->
            <audio-wave class="mr-4"></audio-wave>
            Explore GG's Songs
          </v-btn>
          <div style="position: absolute; bottom: 0; right: 0; background-color: #00000080;" class="px-4 py-3">
            Follow GG on <v-btn icon class="ml-3" href="//youtube.com/@goutamghosal-official" target="_blank"><v-icon x-large color="#CD201F">mdi-youtube</v-icon></v-btn>
              <v-btn icon class="ml-3" href="//www.facebook.com/people/Goutam-Ghosal/61558082112062/" target="_blank"><v-icon x-large color="#1877F2">mdi-facebook</v-icon></v-btn>
          </div>
        </v-img>
        <v-img v-else :src="require('@/assets/img/site/banner-sm-with-sign.png')">
          <div style="width:200px; height:50px; margin-top: 120px;">
            <div v-if="$vuetify.breakpoint.smOnly" class="slide-btn mb-3"><v-btn to="about" small outlined rounded color="secondary lighten-2"
                style="padding-left: 40px; margin-left: -24px; text-transform: none;">About GG</v-btn></div>
            <div v-if="$vuetify.breakpoint.smOnly" class="slide-btn mb-3"><v-btn to="gallery" small outlined rounded color="secondary lighten-2"
                style="padding-left: 40px; margin-left: -24px; text-transform: none;">Gallery</v-btn></div>
            <div v-if="$vuetify.breakpoint.smOnly" class="slide-btn mb-3"><v-btn to="literature" small outlined rounded
                color="secondary lighten-2"
                style="padding-left: 40px; margin-left: -24px; text-transform: none;">Literature</v-btn>
            </div>
            <div v-if="$vuetify.breakpoint.smOnly" class="slide-btn mb-3"><v-btn to="media" small outlined rounded
                color="secondary lighten-2"
                style="padding-left: 40px; margin-left: -24px; text-transform: none;">Media</v-btn></div>
            <div class="slide-btn mb-3"><v-btn to="academy" outlined rounded color="primary"
                style="padding-left: 40px; margin-left: -24px; text-transform: none;">Music Academy</v-btn></div>
            <div class="slide-btn mb-3">
              <v-btn icon class="ml-2" href="//youtube.com/@goutamghosal-official" target="_blank"><v-icon large color="#ba2120">mdi-youtube</v-icon></v-btn>
              <v-btn icon class="ml-2" href="//www.facebook.com/people/Goutam-Ghosal/61558082112062/" target="_blank"><v-icon large color="#2c68b5">mdi-facebook</v-icon></v-btn>
            </div>
          </div>
        </v-img>

        <v-container>
          <div v-if="!$vuetify.breakpoint.mdAndUp" class="text-center mt-10">
            <v-btn to="/discography" color="primary darken-2 elevation-12" large rounded
              style="text-transform: none; width:300px">
              <!-- <v-icon left> mdi-headphones </v-icon> -->
              <audio-wave class="mr-4"></audio-wave>
              Explore GG's Songs
            </v-btn>
          </div>
          <div class="text-center" style="margin-top: 4rem">
            <p style="font-family: Brush Script MT, Brush Script Std, cursive" :style="{
              fontSize: $vuetify.breakpoint.mdAndUp ? '6rem' : '3rem',
            }">
              Sri Goutam Ghosal
            </p>
          </div>
          <!-- <v-row v-if="$vuetify.breakpoint.mdAndUp" class="justify-center" style="margin-top: 1rem">
            <v-card v-for="item in quickTypes" :key="item.title" :color="item.color" class="ma-3 pa-3 text-center"
              width="24rem">
              <span style="fontSize: 1.8rem">{{ item.title }}</span>
            </v-card>
          </v-row>
          <v-row v-else class="justify-center">
            <v-timeline dense>
              <v-timeline-item v-for="item in quickTypes" :key="item.title" :color="item.color" small dark
                :fill-dot="false">
                A {{ item.title }}
              </v-timeline-item>
            </v-timeline>
          </v-row> -->
          <v-row style="margin-top: 1rem">
            <v-carousel cycle continuous :show-arrows="false" :hide-delimiters="true" :height="$vuetify.breakpoint.mdAndUp ? 140 : 180"
              style="max-width: 720px; border-radius: 10px;" class="mx-auto">
              <v-carousel-item v-for="item in quickTypes" :key="item.title">
                <v-sheet height="100%" rounded :style="{ padding: $vuetify.breakpoint.mdAndUp ? '80px' : '30px' }">
                  <div class="d-flex flex-column fill-height justify-center align-center text-center">
                    <div class="mb-3" :class="$vuetify.breakpoint.mdAndUp ? 'text-h4' : 'text-h5'">
                      {{ item.title }}
                    </div>
                    <hr :style="{ border: `3px solid ${item.color}`, width: '70px' }"/>
                    <div class="subtitle mt-3 mb-3">{{ item.text }}</div>
                  </div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-row>

          <section :style="{ marginTop: sectionMarginTop }">
            <home-introduction></home-introduction>
          </section>

          <section :style="{ marginTop: sectionMarginTop }">
            <v-row class="ma-0">
              <v-col cols="12" md="6">
                <home-album></home-album>
              </v-col>
              <v-col cols="12" md="6" :style="$vuetify.breakpoint.mdAndUp
                ? {}
                : { marginTop: sectionMarginTop }
                ">
                <home-popular-songs></home-popular-songs>
              </v-col>
            </v-row>
          </section>

          <section :style="{ marginTop: sectionMarginTop }">
            <home-academy></home-academy>
          </section>

          <section :style="{ marginTop: sectionMarginTop }"></section>
        </v-container>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    quickTypes: [
      {
        title: 'A Singer',
        color: '#6f38c6'
        // text: 'As a singer he is a worthy successor of Bengali legendary singers like Manabendra Mukhopadhyay or Satinath Mukhopadhyay.'
      },
      {
        title: 'A Lyricist',
        color: '#cb2a92'
        // text: 'His lyrics talk about love, peace, affection, childhood, dreams, life. Mother nature also comes in different moods in his songs.'
      },
      {
        title: 'A Composer',
        color: '#248291'
        // text: 'As a composer he carries on the legacy of authentic Bengali music and its essence.'
      },
      {
        title: 'An Author',
        color: '#2c7db5',
        text: ''
      },
      {
        title: 'A Philosopher',
        color: '#0060a0',
        text: ''
      },
      {
        title: 'A Teacher',
        color: '#105860',
        text: ''
      }
    ]
  }),
  computed: {
    ...mapGetters(['isUserAuthenticated']),
    sectionMarginTop () {
      return this.$vuetify.breakpoint.mdAndUp ? '8rem' : '4rem'
    }
  },
  components: {
    HomeIntroduction: () => import('./Introduction'),
    HomeAcademy: () => import('./Academy'),
    HomeAlbum: () => import('./Album'),
    HomePopularSongs: () => import('./PopularSongs'),
    AudioWave: () => import('@/utils/widgets/AudioWave')
  }
}
</script>

<style lang="scss">
.v-carousel__controls {
  height: 36px;
}

.slide-btn {
  position: relative;
  -webkit-animation: slideIn 2s forwards;
  -moz-animation: slideIn 2s forwards;
  animation: slideIn 2s forwards;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
